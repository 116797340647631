<template>
  <view-container scroll-fab>
    <v-card tile flat :style="`overflow-y: auto; ${getViewHeightStyle(56)}`">
      <div class="pb-8 px-5">
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <section-header hide-divider title="Your Cart" />

            <order-items
              v-model="getCartItems"
              editable
              :currency="getCartCurrency"
              :items-feedback="feedback"
              class="mr-3"
            />
          </v-col>
          <v-col cols="12" md="4">
            <section-header
              hide-divider
              rtl
              :title="$t('cart.orderSummary')"
            />
            <v-card class="mt-2">
              <store-card :store="getCartStore" banner round class="mb-1" />
              <v-card flat>
                <v-card-text>
                  Deliver To: <b>{{ getDefaultAddressStringNoTitle }}</b>
                  <v-alert
                    v-if="outOfAos"
                    type="error"
                    class="body-2 lighten-1 px-1 py-0 mb-1"
                    >Address is out of store area of service</v-alert
                  >
                </v-card-text>
                <v-simple-table dense class="grey lighten-4" light>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(item, i) in getCartSummary" :key="i">
                        <td>{{ item.name }}</td>
                        <td class="text-right">
                          {{ getCartCurrency + " " + item.price }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          :class="
                            isFreeDelivery ? 'text-decoration-line-through' : ''
                          "
                        >
                          {{ $t("cart.deliveryFees") }}
                        </td>
                        <td
                          :class="
                            isFreeDelivery
                              ? 'text-decoration-line-through text-right'
                              : 'text-right'
                          "
                        >
                          {{
                            getCartCurrency +
                            " " +
                            (getCartStore ? getCartStore.deliveryFees : 0)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">
                          {{ $t("cart.grandTotal") }}
                        </td>
                        <td class="font-weight-bold text-right">
                          {{
                            getCartCurrency +
                            " " +
                            (notEmptyArray(getCartItems)
                              ? getCartGrandTotal
                              : 0)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-text>
                  <v-alert
                    v-if="!isMinimum"
                    type="error"
                    class="body-2 lighten-1 px-1 py-0 mb-1"
                    dense
                  >
                    The minimum order price for this store is ({{
                      getMinOrderPrice
                    }}), please add a few more items.
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    depressed
                    color="secondary primary--text"
                    @click="goToStore"
                  >
                    <v-icon> mdi-store </v-icon>
                    Add Items
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    depressed
                    color="primary secondary--text"
                    :disabled="
                      !notEmptyArray(getCartItems) || isCartLocked || outOfAos || !isMinimum
                    "
                    @click="proceedToCheckout"
                  >
                    <v-icon> mdi-check-bold </v-icon>
                    Proceed to Checkout
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
        <br />
      </div>
    </v-card>
    <locked-cart store-items address />
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import SectionHeader from "@/common/components/SectionHeader";
import OrderItems from "@/views/orders/OrderItems";
import LockedCart from "./LockedCart";
import StoreCard from "@/views/stores/StoreCard";

export default {
  name: "Cart",
  components: {
    ViewContainer,
    SectionHeader,
    OrderItems,
    StoreCard,
    LockedCart,
  },
  data() {
    return {
      feedback: [],
      outOfAos: false,
    };
  },
  mounted() {
    this.loadStore(this.getStoreIdParam);
    const { activeAddress } = this.$getUserInfo();
    this.isLocationInAos({
      storeId: this.getStoreIdParam,
      latitude: activeAddress?.latitude,
      longitude: activeAddress?.longitude,
    }).then((inAos) => (this.outOfAos = !inAos));

    if (this.notEmptyArray(this.getCartItems) && !this.isCartLocked) {
      this.preCheckCart().then((feedback) => {
        if (this.notEmptyArray(feedback)) {
          this.feedback = feedback;
        } else {
          this.feedback = [];
        }
      });
    }
  },
  computed: {
    ...mapGetters("cart", [
      "getCartStore",
      "getCartItems",
      "getCartTotalPrice",
      "getCartGrandTotal",
      "isFreeDelivery",
      "getCartCurrency",
      "isCartLocked",
    ]),
    ...mapGetters("stores", ["getStoreById"]),
    ...mapGetters("addresses", ["getAddresses"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
    getCartSummary() {
      const summary = [
        { name: this.$t("cart.itemsPrice"), price: this.getCartTotalPrice },
      ];
      return summary;
    },
    isMinimum() {
      const mno = this.getCartStore.minimumOrderPrice || 0;
      return this.getCartTotalPrice >= mno;
    },
    getMinOrderPrice() {
      const mno = this.getCartStore.minimumOrderPrice || 0;
      const region = this.getCartStore.region;
      return (
        region?.currency +
        " " +
        mno?.toLocaleString({ minimumFractionDigits: 2 })
      );
    },
    getDeliveryFees() {
      const { deliveryFees } = this.getCartStore;
      return this.isFreeDelivery ? deliveryFees : 0;
    },
    getDefaultAddressString() {
      const { activeAddress: a } = this.$getUserInfo();
      return `${a.title} / ${a.buildingNumber} ${a.street}, ${
        a.area ? a.area : ""
      } (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
    getDefaultAddressStringNoTitle() {
      const { activeAddress: a } = this.$getUserInfo();
      return `${a.buildingNumber} ${a.street}, ${a.area ? a.area : ""} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
  },
  methods: {
    ...mapActions("cart", ["preCheckCart", "loadStore"]),
    ...mapActions("stores", ["loadStore", "isLocationInAos"]),
    ...mapActions("addresses", ["loadAddresses"]),

    goToStore() {
      this.$$router.push({
        name: "store",
        params: { storeId: this.getStoreIdParam, back: { name: this.$route.name } },
      });
    },
    proceedToCheckout() {
      this.$$router.push({
        name: "checkout",
        params: { storeId: this.getStoreIdParam, back: { name: this.$route.name } },
      });
    },
  },
};
</script>
